import React from "react"

const MainContent = () => {
    return (
        <div className='parent' style={{ backgroundImage: "url('/background.jpg')", backgroundSize: 'cover', height: '100vh', width: '100%' }}>
            <div id='coming-soon'>
                COMING SOON!!
            </div>
            <div className="button-container">
            <button id="get-notified">
                GET NOTIFIED
            </button>
            </div>
            <div id="local-chatbot">
                VOCAL FOR <br/> LOCAL
            </div>
            <div id="description">
                <span id="heading">NAMMABOT</span>
                <span id="sub-heading">is coming soon</span>
                <span id="content">Facing problems along the day? <br/> NammaBot is here to help !<br/> JUST A PROMPT AWAY</span>
            </div>
            <div id="title">
                AI-CHATBOT
            </div>
            <button id='download'>
                DOWNLOAD
            </button>
        </div>
    )
}

export default MainContent