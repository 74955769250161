import React from "react";
import MainContent from "./landing_page/MainContent.jsx";
import './App.css'

const App = () => {
  return (
    <div className="flex overflow-hidden flex-col bg-black bg-opacity-0">
      <MainContent />
    </div>
  );
};

export default App;
